import React, { useContext, useEffect } from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import { Seo } from "../components/Seo"
import { PreviewContext } from "../context/PreviewContext"
import Page from "../templates/Page"
import { useLocation } from "@reach/router"

const getSlug = (url) => new URL(url).pathname.match(/[^\/]+/g)

const NotFoundPage = ({ data }) => {
  const {
    previewActive,
    setPreviewActive,
    previewContextData,
    setPreviewContextData,
    previewIsLoading,
    setPreviewIsLoading,
    previewValidationData,
    setPreviewValidationData,
  } = useContext(PreviewContext)
  const location = useLocation()
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const previewModeParameter = urlSearchParams.get("previewMode")
    const previewDatasetParameter = urlSearchParams.get("previewDataset")
    const previewValidationDataParameter = urlSearchParams.get("validation")

    if (previewValidationDataParameter) {
      setPreviewValidationData(JSON.parse(previewValidationDataParameter))
    }

    if (previewModeParameter) {
      setPreviewActive(true)
    }
    if (previewDatasetParameter) {
      setPreviewContextData({ previewDataset: previewDatasetParameter })
    }
  }, [])

  if (previewActive) {
    const pageData = {
      ...data,
      sanityPage: {
        content: {
          main: {
            slug: {
              current: getSlug(window.location.href)[0],
            },
          }
        }
      },
    }
    return <Page data={pageData}></Page>
  }
  return (
  <>
    <Helmet
      htmlAttributes={{
        class: "html-class"
      }}
    >
      <style type="text/css">{`
          
        `}</style>
    </Helmet>
    <Layout>
      <Seo title="404: Diese Seite wurde nicht gefunden" />
      <div className="bg-white container-wrapper">
        <div className="flex flex-col px-3 mt-12 w-full min-h-screen lg:mt-24 lg:items-center">
          <h1 className="z-10 mb-3 text-4xl tracking-wide xxs:text-5xl lg:text-6xl text-primaryRed-500 xl:text-7xl mobile-h2">
            404: Seite nicht gefunden
          </h1>
          <p className="text-base mobile-p md:text-lg lg:mt-5 lg:text-2xl md:mt-0">
            Diese Seite scheint wohl nicht zu existieren.
            <br />
            <Link
              className="underline text-primaryRed-500"
              to="/"
              aria-label="Link zur Landingpage"
            >
              Hier geht's zur Homepage.
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  </>
)}

export default NotFoundPage
