import React, { useContext, useEffect } from "react"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { Seo } from "../components/Seo"
import { ConnectModules } from "../utils/ConnectModules"
import { PreviewContext } from "../context/PreviewContext"
import { pageQuery } from "../queries/documentQueries"
import { useLiveQuery } from "@sanity/preview-kit"

const Page = props => {
  const { data } = props
  const {
    previewActive,
    setPreviewActive,
    setPreviewContextData,
    setPreviewIsLoading,
    setPreviewValidationData,
    setIsNewUnpublishedDoc,
    isNewUnpublishedDoc
  } = useContext(PreviewContext)

  const [previewData, sanityPreviewIsLoading] = useLiveQuery(null, pageQuery, {
    slug: data?.sanityPage?.content?.main?.slug?.current
  })

  const slug = data?.sanityPage?.content?.main?.slug?.current

  const location = useLocation()

  useEffect(() => {
    setPreviewIsLoading(sanityPreviewIsLoading)
  }, [sanityPreviewIsLoading])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const previewModeParameter = urlSearchParams.get("previewMode")
    const previewDatasetParameter = urlSearchParams.get("previewDataset")
    const previewValidationDataParameter = urlSearchParams.get("validation")
    const previewIsNewUnpublishedDocParameter =
      urlSearchParams.get("isNewUnpublishedDoc") === "true"

    if (previewValidationDataParameter) {
      setPreviewValidationData(JSON.parse(previewValidationDataParameter))
    }

    if (previewModeParameter) {
      setPreviewActive(true)
    }
    if (previewDatasetParameter) {
      setPreviewContextData({ previewDataset: previewDatasetParameter })
    }

    if (previewIsNewUnpublishedDocParameter) {
      setIsNewUnpublishedDoc(previewIsNewUnpublishedDocParameter)
    }
  }, [])

  if (sanityPreviewIsLoading) {
    return <div>Loading...</div>
  }

  const modules = previewActive
    ? previewData?.content?.main?.modules
    : data.sanityPage.content.main.modules
  const meta = data.sanityPage.content.meta

  return (
    <Layout>
      <Seo currentResourceMetaInformation={meta} pagePath={location} />
      <div className="">
        {ConnectModules(modules, null, null, null, null, previewActive, slug)}
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      content {
        main {
          slug {
            current
          }
          modules {
            ...moduleBgImageCTAQuery
            ...moduleScrollingBannerQuery
            ...moduleNavigationCardsQuery
            ...moduleCommunitySectionQuery
            ...moduleProductInterfaceQuery
            ...moduleReviewsQuery
            ...moduleCustomerImagesQuery
            ...moduleAboutArtistQuery
            ...moduleContentBoxQuery
            ...moduleFollowUsQuery
            ...moduleRelatedProductsQuery
            ...moduleTikTokEmbedQuery
            ...moduleProductOverviewQuery
            ...moduleProductGridQuery
            ...moduleTextAndCarouselQuery
            ...modulePortraitQuery
            ...moduleHabitatSectionQuery
            ...moduleInfluenceSectionQuery
            ...moduleCertificationsSectionQuery
            ...moduleClimatePositiveSectionQuery
            ...moduleProductionSectionQuery
            ...moduleProductionLocationQuery
            ...moduleNewsletterFormQuery
            ...moduleYoutubeEmbedQuery
            #...moduleWarrantyClaimFormQuery
            ...modulePreorderCampaignHeroQuery
            ...modulePreorderPhasesQuery
            ...modulePreorderUpdateListQuery
            ...moduleModularTabsQuery
            ...moduleBlockContentQuery
            ...moduleCardsCarouselQuery
            ...moduleImageAndTextHeroQuery
            ...moduleTitleAndTextQuery
            ...moduleButtonsQuery
            ...moduleImagesCarouselAndDescriptionQuery
            ...moduleBigImageQuery
            ...moduleBlockContentAndTitleQuery
            ...moduleImageAndLinkQuery
            ...moduleModalQuery
            ...moduleFAQsectionQuery
            ...moduleVideoBlockQuery
            ...moduleProductGalleryQuery
          }
        }
        meta {
          _key
          metaKeywords
          metaTitle
          metaDescription
          openImage {
            asset {
              url
            }
          }
          openTitle
          openGraphDescription
          twitterImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
